import React, {useState} from 'react';
// import axios from 'axios'
// import { normalize, schema } from 'normalizr'

// import {loadLocal,saveLocal,useLocalState} from '../commands/LocalStorage'
// const touchpointsUrl = process.env.REACT_APP_API_BASE+'touchpoints'

const AppContext = React.createContext();


const AppProvider = (props) => {
  
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [flash, setFlash] = useState(null)


    // const getTouchpoints = () => {
    //   // console.log("GET CAMPAIGNS ")
    //   setIsLoading(true)
    //   if(Object.keys(touchpoints).length == 0) {
    //     // console.log("AUTH", localStorage.getItem('token'))
    //     axios.get(touchpointsUrl, { headers: {"Authorization": localStorage.getItem('token') }}).then(
    //         response => {
    //                 // console.log("GET CAMPAIGNS RESPONSE")
    //                 if( response.status == 200){
    //                   const touchpointSchema = new schema.Array(new schema.Entity('touchpoints'))
    //                   const normalizedData = normalize(response.data, touchpointSchema)
    //                   const touchpoints = normalizedData.entities.touchpoints
    //                   setTouchpoints(touchpoints)
    //                   saveLocal('touchpoints', touchpoints)
                      
    //                 }else{
    //                   setIsError("Oops,we couldn't retrieve your touchpoints,*try again* , and if the problem persists, please *contact us*")
    //                   setTouchpoints({})
    //                 }
    //                 setIsLoading(false)
    //         }
    //     ).catch( 

    //         (e) => {
    //           setIsLoading(false)
    //         }
    //     )
    //   }

    // }
    
    return (
        <AppContext.Provider value={{ 
                        flash,
                        isLoading,
                        setIsLoading,
                        isError,
                        setIsError,
                        setFlash
                        }}>
          {props.children}
        </AppContext.Provider>
      )
}

const AppConsumer = AppContext.Consumer


export {AppContext, AppProvider, AppConsumer }