
import React from 'react'
import styles from './ButtonPairComponent.module.sass'
import cx from 'classnames'
import { HashLink as Link } from "react-router-hash-link";
import { withRouter } from "react-router-dom";
/*
 * ButtonPair Component 
 */
const ButtonPair = props => (
  <div
    className={cx(
      "buttonpair-component col-contents text-center",
      styles.container,
      props.className
    )}
  >
    <Link
      className={"btn text-black btn-" + (props.btn1Type || "primary") + " btn-lg mr-2"}
      to={ props.action || "/get-in-touch"}
    >
      {props.btn1Label || "Get a Demo"}
    </Link>
    {/* <Link
      type="button"
      className={"btn btn-" + (props.btn2Type || "text") + " btn-lg"}
      onClick={() => {
        props.btn2Action();
      }}
    >
      {props.btn2Label || "Learn More"}
    </Link> */}
  </div>
);

ButtonPair.defaultProps = {
    btn1Action: () => null,
    btn2Action: () => null
}

export default withRouter(ButtonPair)


