
import React from 'react'
import styles from './PrivacyPolicyPageStyles.module.sass'

/**
 * PrivacyPolicy Page
 * 
 * @author Dave Ashenden <dave@thesubversivesagency.com>
 */
const PrivacyPolicyPage = (props) => {
    return (
      <section className="container">
        {/* <h1 className="section-header mb-5">
          So, we know what you’re thinking, who are matter? Or more to the
          point, what is matter?
        </h1> */}
        <div className="row">
          <div className="col-md-8 offset-md-2 d-flex justify-content-center flex-column">
            <h1 className="section-header mb-5"> Privacy Policy</h1>
            We are committed to safeguarding the privacy of those using our
            services. This policy sets out how we will treat your personal
            information. matter DM LTD (the “Company”) is committed to
            protecting the privacy of its users. This Privacy Policy
            (“Privacy Policy”) is designed to help you understand what
            information we gather, how we use it, what we do to protect it,
            and to assist you in making informed decisions when using our
            Service. Unless otherwise indicated below, this Privacy Policy
            applies to any website that references this Privacy Policy, any
            Company website, as well as any data the Company may collect
            across partnered and unaffiliated sites. For purposes of this
            Agreement, “Site” refers the webpage hosted at matter.so and
            “Service” refers to the Company’s service which can be accessed
            via our Site at matter.so or through our mobile applications.
            The terms “we,” “us,” and “our” refer to the Company. “You”
            refers to you, as a user of Service.
            <h5 className="text-lowercase text-center my-3">GDPR</h5>
            The General Data Protection Regulation (GDPR), passed in 2016,
            is an EU regulation that expands the protection of personal data
            of EU citizens. It also expands the obligations of organizations
            who collect or process that data. At matter DM we strongly
            support the goals of GDPR to increase transparency and fairness
            in the handling of individuals’ personal information.
            <h5 className="text-lowercase text-center my-3">
              PERSONAL INFORMATION WE COLLECT
            </h5>
            When you visit the Site, we automatically collect certain
            information about your device, including information about your
            web browser, IP address, time zone, and some of the cookies that
            are installed on your device. Additionally, as you browse the
            Site, we collect information about the individual web pages or
            products that you view, what websites or search terms referred
            you to the Site, and information about how you interact with the
            Site. We refer to this automatically-collected information as
            “Device Information.”
            <h5 className="text-lowercase text-center my-3">
              We collect Device Information using the following
              technologies:
            </h5>
            “Cookies” are data files that are placed on your device or
            computer and often include an anonymous unique identifier. For
            more information about cookies, and how to disable cookies,
            visit http://www.allaboutcookies.org. “Log files” track actions
            occurring on the Site, and collect data including your IP
            address, browser type, Internet service provider, referring/exit
            pages, and date/time stamps. “Web beacons,” “tags,” and “pixels”
            are electronic files used to record information about how you
            browse the Site. Additionally when you register as a user of our
            Service, we collect certain information from you, including your
            name, company billing address, the company you work for, and
            email address. We refer to this information as “User
            Information.” When we talk about “Personal Information” in this
            Privacy Policy, we are talking both about Device Information and
            User Information.
            <h5 className="text-lowercase text-center my-3">
              HOW DO WE USE YOUR PERSONAL INFORMATION?
            </h5>
            We use the User Information that we collectenable your use of
            the Service; to fulfill any orders placed through the Site
            (including processing your order, arranging for shipping, and
            preparing invoices and/or order confirmations). personalise the
            Service for you; improve the Service; send you non-marketing
            communication including but not limited to technical service
            notifications, responses to your customer support requests,
            account statements and invoices, and any notifications you have
            requested; We use the Device Information that we collect to help
            us identify any problems with our website or platform by
            detecting bugs or errors that might occur, and more generally to
            improve and optimize our Site (for example, by generating
            analytics about how our customers browse and interact with the
            Site, and to assess the success of our marketing and advertising
            campaigns).
            <h5 className="text-lowercase text-center my-3">Analytics</h5>
            We use Google Analytics, Segment and Mixpanel to measure and
            evaluate access to our Site. Google Analytics, Segment and
            Mixpanel operate independently from us and have their own
            privacy policies. Google may use the information collected
            through Google Analytics to evaluate Users’ and Visitors’
            activity on our Site. For more information:{" "}
            <a
              href="https://www.google.com/intl/en/policies/privacy/"
              className="d-inline"
            >
              https://www.google.com/intl/en/policies/privacy/
            </a>. You can also opt-out of Google Analytics here:<a
              href="https://tools.google.com/dlpage/gaoptout"
              className="d-inline"
            >
              https://tools.google.com/dlpage/gaoptout
            </a>. We have put in place strong measures to protect the
            information collected through Google Analytics and Mixpanel. We
            have limited the access to our accounts on these platforms, and
            the data collected will only be used on a need to know basis to
            resolve technical issues, administer the Site and identify
            visitor preferences.
            <h5 className="text-lowercase text-center my-3">
              THIRD PARTIES
            </h5>
            We work with third party service providers who provide website,
            application development, hosting, maintenance, and other
            services for us. These third parties may have access to, or
            process Personal Data or Client Data as part of providing those
            services for us. We limit the information provided to these
            service providers to that which is reasonably necessary for them
            to perform their functions, and our contracts with them require
            them to maintain the confidentiality of such information.
            <h5 className="text-lowercase text-center my-3">COOKIES</h5>
            The following cookies are used on our site: _ga, _gat, _gid -
            These are Google Analytics cookies that identify your session on
            our Site ajs_anonymous_id, ajs_group_id, ajs_user_id - These are
            Segment cookies that identify your session on our Site
            cookieconsent_status - this is a cookie which stores information
            about whether you have been informed about our cookies on our
            site
            <h5 className="text-lowercase text-center my-3">
              DO NOT TRACK
            </h5>
            Please note that we do not alter our Site’s data collection and
            use practices when we see a Do Not Track signal from your
            browser.
            <h5 className="text-lowercase text-center my-3">YOUR RIGHTS</h5>
            If you are a European resident, you have the right to access
            personal information we hold about you and to ask that your
            personal information be corrected, updated, or deleted. If you
            would like to exercise this right, please contact us through the
            contact information below. Additionally, if you are a European
            resident we note that we are processing your information in
            order to fulfill contracts we might have with you (for example
            if you are a registered user of our service and make orders
            through the Site), or otherwise to pursue our legitimate
            business interests listed above. Information that we collect may
            be stored and processed in and transferred between any of the
            countries in which we operate in order to enable us to use the
            information in accordance with this privacy policy. This may
            result in your personal information being transferred to
            countries (including the United States of America) which do not
            have data protection laws equivalent to those in force in our
            home jurisdiction. You expressly agree to such transfers of
            personal information.
            <h5 className="text-lowercase text-center my-3">
              SECURING YOUR PERSONAL DATA
            </h5>
            We will take reasonable technical and organisational precautions
            to prevent the loss, misuse or alteration of your personal
            information. You are responsible for keeping your password and
            user details confidential. We will not ask you for your password
            (except when you log in to the website). We are unable to
            recover your lost or forgotten password, but you will be able to
            generate a new one if you still have access to the email address
            associated with your user account.
            <h5 className="text-lowercase text-center my-3">
              DATA RETENTION
            </h5>
            When you place an order through the Site, we will maintain your
            User Information for our records unless and until you ask us to
            delete this information. Order information you provide for
            create an order is kept for a limited time only, in order to
            process and send the order, conduct quality assurance,
            duplication checking, and invoice for the order.
            <h5 className="text-lowercase text-center my-3">CHANGES</h5>
            We may update this privacy policy from time to time in order to
            reflect, for example, changes to our practices or for other
            operational, legal or regulatory reasons.
            <h5 className="text-lowercase text-center my-3">CONTACT US</h5>
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please
            contact us by e-mail at privacy@matter.so or send a letter to
            matter DM Ltd, 2 Barnfield Crescent, Exeter, Devon, United
            Kingdom, EX1 1QT
          </div>
        </div>
      </section>
    );
}

export default PrivacyPolicyPage


