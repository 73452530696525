import React from "react";
import styles from "./ContactFormComponent.module.sass";
import cx from "classnames";

import Form from "react-jsonschema-form";

const JSONSchema = {
  //   title: "A registration form",
  type: "object",
  required: ["firstName", "lastName"],
  properties: {
    name: {
      type: "string",
      title: "Name",
      minLength: 3
    },
    email: {
      title: "Email",
      type: "string",
      format: "email"
    },
    message: {
      type: "string",
      title: "Message"
    },
    phone: {
      type: "string",
      title: "Telephone",
      minLength: 9
    },
    // budget: {
    //   title: "Budget",
    //   type: "string",
    //   oneOf: [
    //     {
    //       type: "string",
    //       enum: ["#ff0000"],
    //       title: "Red"
    //     },
    //     {
    //       type: "string",
    //       enum: ["#00ff00"],
    //       title: "Green"
    //     },
    //     {
    //       type: "string",
    //       enum: ["#0000ff"],
    //       title: "Blue"
    //     }
    //   ]
    // },
    follow_up_method: {
      title: "Contact me via:",
      type: "string",
      default: "either",
      oneOf: [
        {
          type: "string",
          enum: ["either"],
          title: "Either phone or email"
        },
        {
          type: "string",
          enum: ["phone"],
          title: "Phone"
        },
        {
          type: "string",
          enum: ["email"],
          title: "Email"
        }
      ]
    }
  }
};

const UISchema = {
  name: {
    "ui:autofocus": true,
    "ui:emptyValue": ""
  },
  message: {
    "ui:widget": "textarea",
    classNames: "input-large"
  },
  phone: {
    "ui:options": {
      inputType: "tel"
    }
  },
  follow_up_method: {
    "ui:widget": "radio"
  },
  budget: {
    "ui:widget": "radio"
  }
};

const CustomTitleField = ({ title, required }) => {
  
  
  return null; //<div id="custom">YEAH {legend}</div>;
};

const fields = {
  TitleField: CustomTitleField,
  LabelField: CustomTitleField
};
/*
 * ContactForm Component
 */
const ContactForm = props => (
  <div className={cx("container contactform-component", styles.container)}>
    <div className="row">
      <div className="col-12 pt-4">
        <div className="card">
          <div className="card-body p-5">
            <Form
              schema={JSONSchema}
              uiSchema={UISchema}
              fields={fields}
              onChange={console.log("changed")}
              onSubmit={console.log("submitted")}
              onError={console.log("errors")}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ContactForm;
