
import React from 'react'
import styles from './SupplierPageStyles.module.sass'


/**
 * Supplier Page
 * 
 * @author Dave Ashenden <dave@thesubversivesagency.com>
 */
const SupplierPage = (props) => {
    return (
    <div className="holding">
        Supplier Component
    </div>

)
}

export default SupplierPage


