import React from "react";
import styles from "./FooterComponent.module.sass";
import cx from "classnames";
import { Link } from "react-router-dom";
import InlineBrandMark from "../InlineBrandMark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import { faCoffee, faInstagram, faTwitter } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faTwitter,
  faMedium,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";

/*
 * Footer Component
 */
const Footer = props => (
  <div className={cx("footer-component", styles.container)}>
    <div className="container">
      <div className="ml-auto d-flex align-items-center justify-content-center">
       {false && <div className="row">
          <div className="col-md">
            <h5>matter</h5>
            <ul className="special">
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/get-in-touch">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col-md">
            <h5>
              Reasons to use{" "}
              <InlineBrandMark
                inverse={true}
                style={{
                  maxHeight: "1.5em",
                  marginTop: "-4px",
                  marginLeft: "0px",
                  marginRight: "0px"
                }}
              />
            </h5>
            <ul className="special">
              <li>
                <Link to="/for/direct-mail">Direct Mail as a service</Link>
              </li>
              {/* <li>
                <Link to="/for/supercharging-sales">
                  Supercharging sales - A guide to Gifting and Coffee
                </Link>
              </li> */}
              <li>
                <Link to="/for/sentiment-as-a-service">
                  Say 'Sorry', 'Thankyou' or 'Hello' with on demand personalised gifting
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md">
            <h5 className="">Contact Us</h5>
            <ul className="special">
              <li>
                <a href="malto:hello@matter.so">hello@matter.so</a>
              </li>
            </ul>
            <h5 className="">Connect with us!</h5>
            <span className="social Icons">
              <a
                href="https://www.instagram.com/matter_so"
                target="_blank"
                rel="noopener noreferrer"
                title="Our Insta! We often do some really cool campaigns. we show them off here"
              >
                <FontAwesomeIcon size="2x" icon={faInstagram} className="m-2" />
              </a>
              <a
                href="https://twitter.com/matter_so"
                target="_blank"
                rel="noopener noreferrer"
                title="Follow us on Twitter for insights into DM"
              >
                <FontAwesomeIcon size="2x" icon={faTwitter} className="m-2" />
              </a>
              <a
                href="https://medium.com/@matter_so"
                target="_blank"
                rel="noopener noreferrer"
                title="Our blog on medium, full of our learnings"
              >
                <FontAwesomeIcon size="2x" icon={faMedium} className="m-2" />
              </a>
              <a
                href="https://www.linkedin.com/company/14007019/"
                target="_blank"
                rel="noopener noreferrer"
                title="Connect with us on LinkedIn"
              >
                <FontAwesomeIcon size="2x" icon={faLinkedin} className="m-2" />
              </a>
            </span>
          </div>
        </div>}
      </div>
      <div className="row">
        <div className="col-12 text-center">
          2018 &copy; matter DM Ltd Reg no. 11980073, 2 Barnfield Crescent,
          Exeter, Devon, United Kingdom, EX1 1QT
        </div>
      </div>
      <div className="d-none">
        Icons made by{" "}
        <a
          href="https://www.flaticon.com/authors/dinosoftlabs"
          title="DinosoftLabs"
        >
          DinosoftLabs
        </a>{" "}
        from{" "}
        <a href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>{" "}
        is licensed by{" "}
        <a
          href="http://creativecommons.org/licenses/by/3.0/"
          title="Creative Commons BY 3.0"
          target="_blank"
           rel="noopener noreferrer"
        >
          CC 3.0 BY
        </a>
      </div>
    </div>
  </div>
);

export default Footer;
