import React from "react";
import styles from "./SectionLandingOfflineComponent.module.sass";
import cx from "classnames";
import LazyLoad from "react-lazy-load";


import BrandImg from "../../images/matter-logo.png";
import SendWhatmattersImg from "../../images/send-what-matters-diagram.png";
import ButtonPair from "../ButtonPair";
import LazyImage from "../LazyImage";
/*
 * SectionLandingOffline Component
 */
const SectionLandingOffline = props => (
  <div className={cx("sectionlanding-component", styles.container)}>
    <section>
      <div
        className="is-valigned hero h-100"
        style={{ padding: " 0rem 0 0 0" }}
      >
        <div className="container h-80 text-center" style={{ padding: "0 2em", minHeight: '20vh' }}>

          {/* <LazyImage
                  src={SendWhatmattersImg}
                  className="img-fluid"
                  alt="matter Logo"
                /> */}

                <img
              src={BrandImg}
              className={cx(
                "img-fluid",
                "d-block d-lg-block"
              )}
              id="logo"
              alt="matter Logo"
              style={{margin: "1em auto", maxHeight: '100px'}}
            />
          <h1>Matter.so is now matterdm.com</h1>
          <p><strong>Please update your address books</strong></p>
          {/* <h1>Matter.so is undergoing maintenance</h1> */}
          {/* <p><strong>Please update your address books</strong></p> */}
          <p>We hope to be back up and running soon. You can contact us directly below with any enquiries.</p>

        </div>
      </div>
    </section>
  </div>
);

export default SectionLandingOffline;
