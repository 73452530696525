import React, { useState } from "react";
import styles from "./ContactWizardComponent.module.sass";
import cx from "classnames";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { FormGroup, Label, FormFeedback, Input, Button } from "reactstrap";
import InlineBrandMark from "../InlineBrandMark";
import LazyImage from "../LazyImage";
import highFive from "../../images/high-five.gif";

const SignupSchema = Yup.object().shape({
  message: Yup.string().required(
    "Tell usxw about your DM and gifting needs, or simply say hi!"
  ),
  name: Yup.string().required("My name is Dave, Whats yours?"),
  company: Yup.string()
    .min(2, "Seems a little short for a company name..")
    .required("We'd really like to be able to look you up!"),
  email: Yup.string()
    .email("Your email doesn't look quite right")
    .required("required")
});

const customInputForm = ({ field, form: { touched, errors }, ...props }) => (
  <div>
    <Input
      invalid={!!(touched[field.name] && errors[field.name])}
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && (
      <FormFeedback>{errors[field.name]}</FormFeedback>
    )}
  </div>
);
const customTextAreaForm = ({ field, form: { touched, errors }, ...props }) => (
  <div>
    <Input
      type="textarea"
      invalid={!!(touched[field.name] && errors[field.name])}
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && (
      <FormFeedback>{errors[field.name]}</FormFeedback>
    )}
  </div>
);
console.log(" THE ENV IS " + process.env.NODE_ENV);
const funcPath =
  process.env.NODE_ENV !== "production"
    ? "//localhost:34567/"
    : "/.netlify/functions/";

/*
 * ContactWizard Component
 */
const ContactWizard = props => {
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);

  return (
    <div
      className={cx(
        "contactwizard-component card m-md-5 p-md-5 shadow-lg",
        styles.container
      )}
    >
      <div className="card-body">
        <a name="contact">
        {isSent && !isLoading && (
          <div>
            <h3 className="text-center section-header mb-5">
              Your message is on it's way
            </h3>
            
            <p
              className="strapline text-center d-block w-100 "
              style={{ maxWidth: "auto" }}
            >
              Our team will be in touch shortly. We're super excited to chat!
            </p>
            <div
              style={{
                width: "100%",
                height: 0,
                paddingBottom: "46%",
                position: "relative"
              }}
            >
              <LazyImage
                src={highFive}/>
              {/* <iframe
                src="https://giphy.com/embed/DohrJX1h2W5RC"
                width="100%"
                height="100%"
                style={{ position: "absolute" }}
                frameBorder="0"
                class="giphy-embed"
                allowFullScreen
              /> */}
            </div>
            {/* <p>
              <a href="https://giphy.com/gifs/high-five-friends-rachel-DohrJX1h2W5RC">
                via GIPHY
              </a>
            </p> */}
          </div>
        )}
        {isLoading && (
          <React.Fragment>
            <h3 className="text-center section-header mb-5">
              Sending, just a moment
            </h3>
          </React.Fragment>
        )}
        {!isSent && !isLoading && (
          <React.Fragment>
            <h3 className="text-center section-header mb-5">
              Let's start a conversation
            </h3>
            {isError && (
              <div className="alert">Oops, somthign went wrong</div>
            )}
            <Formik
              // initialValues={{
              //   email: "daveashenden@gmail.com",
              //   message: " This is a message",
              //   name: "Dave Ashenden",
              //   company: "matter DM Ltd",
              //   phone: "077099999999"
              // }}
              validationSchema={SignupSchema}
              onSubmit={values => {
                // same shape as initial values
                console.log(values);
                setError(false);
                setIsLoading(true);
                fetch(funcPath + "trigger-zapier", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                  },
                  body: JSON.stringify(values),
                  credentials: "same-origin",
                  mode: "no-cors"
                })
                  .then(data => {
                    setIsSent(true);
                    setIsLoading(false);
                    setError(false);
                  })
                  .catch(error => {
                    setIsLoading(false);
                    setIsSent(false);
                    setError(true);
                  });
              }}
            >
              <Form>
                <FormGroup>
                  <Label for="name">Your Name</Label>
                  <Field
                    name="name"
                    type={"text"}
                    placholder="Your full name"
                    component={customInputForm}
                  />
                </FormGroup>
                <FormGroup>
                  <div className="row">
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md">
                      <Label for="email">Company Email</Label>
                      <Field
                        name="email"
                        type={"email"}
                        placholder="e.g: jonathan@matter.so"
                        component={customInputForm}
                      />
                    </div>
                    <div className="col-md">
                      <Label for="company">Company Name</Label>
                      <Field
                        name="company"
                        placeholder="Your Company's Name"
                        type={"text"}
                        component={customInputForm}
                      />
                    </div>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for="phone">Phone Number</Label>
                  <Field
                    name="phone"
                    type={"text"}
                    component={customInputForm}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="message">
                    How can{" "}
                    <InlineBrandMark
                      style={{
                        maxHeight: "1.25em",
                        marginTop: "-5px",
                        marginLeft: "-1px",
                        marginRight: "-1px"
                      }}
                    />{" "}
                    help you
                  </Label>
                  <Field
                    name="message"
                    type={"textarea"}
                    component={customTextAreaForm}
                  />
                </FormGroup>
                <Button>Submit</Button>
              </Form>
            </Formik>
          </React.Fragment>
        )}
        </a>
      </div>
    </div>
  );
};

export default ContactWizard;
