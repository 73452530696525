import React from "react";
import cx from "classnames";
import styles from "./NavBar.module.sass";
import BrandImg from "../../images/matter-logo.png";
import BrandIconImg from "../../images/matter-icon.png";
import { Collapse, NavbarToggler } from "reactstrap";
import { Link } from "react-router-dom";
class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <div className="container">
        <nav className="navbar navbar-expand-md navbar-light">
          <Link
            to="/"
            onClick={() => {}}
            role="link"
            className="mr-auto navbar-brand"
          >
            <img
              src={BrandImg}
              className={cx(
                "img-fluid",
                styles.logo,
                "d-block d-md-none d-lg-block"
              )}
              id="logo"
              alt="matter Logo"
            />
            <img
              src={BrandIconImg}
              className={cx(
                "img-fluid",
                styles.logo,
                " d-none d-md-block d-lg-none"
              )}
              id="logo"
              alt="matter Logo"
            />
          </Link>
          <NavbarToggler onClick={this.toggleNavbar} />
          <Collapse isOpen={!this.state.collapsed} navbar>
            <ul className="ml-auto navbar-nav">
              {/* <li className={cx("nav-item", styles.navItem)}>
                <Link
                  to="/"
                  onClick={() => {
                    
                  }}
                  role="link"
                  className="nav-link navigation__navlinks"
                >
                  Customer Stories
                </Link>
              </li>*/}
              <li className={cx("nav-item", styles.navItem)}>
                <Link
                  to="/about-us"
                  role="link"
                  className="nav-link navigation__navlinks"
                >
                  Meet the Team
                </Link>
              </li>
              <li className={cx("nav-item", styles.navItem)}>
                <Link
                  to="/features"
                  // onClick={() => {
                  //
                  // }}
                  role="link"
                  className="nav-link navigation__navlinks"
                >
                  Features
                </Link>
              </li>
              {/*
              <li className={cx("nav-item", styles.navItem)}>
                <Link
                  to="/"
                  onClick={() => {
                    
                  }}
                  role="link"
                  className="nav-link navigation__navlinks"
                >
                  Contact
                </Link>
              </li> */}
              <li className={cx("nav-item", styles.navItem)}>
                <Link
                  to="/get-in-touch"
                  onClick={() => {}}
                  role="link"
                  className="btn btn-primary btn-sm mx-2"
                >
                  {/* <button className="btn  btn-primary"> */}
                  Get a Demo
                  {/* </button> */}
                </Link>
              </li>
            </ul>
          </Collapse>
        </nav>
      </div>
    );
  }
}

export default NavBar;
