import React from "react";
import styles from "./SectionContactComponent.module.sass";
import cx from "classnames";
import CooPic from "../../images/coo.png";
import SectionWithTitle from "../../components/SectionWithTitle";
import ContactForm from "../ContactForm";
import InlineBrandMark from "../InlineBrandMark";
import ContactWizard from "../ContactWizard";
/*
 * SectionContact Component
 */
const SectionContact = props => {
  // const zapHookUrl = "https://hooks.zapier.com/hooks/catch/5003661/jn0ui1/";

  // useEffect(() => {
  //   const formData = {
  //     name: "Dave Ashenden",
  //     email: "Daveashenden@gmail.com",
  //     phone: "088998232",
  //     message: "This is my message",
  //     tags: "sorry,sme,uk",
  //     follow_up_method: "email",
  //     time: "ASAP"
  //   };
  //   fetch(zapHookUrl, {
  //     method: "POST",
  //     headers: { "Content-Type": 'application/json' },
  //     body: formData,
  //     credentials: "same-origin",
  //     mode: "no-cors"
  //   });
  // }, []);

  return (

    <div className={cx("sectioncontact-component p-md-5 mb-10", styles.container)}>
      <div className="ml-auto d-flex align-items-center justify-content-center">
        <ContactForm />

      </div>

    </div>
  );
};

export default SectionContact;
