import React from "react";
import SectionLandingOffline from "../../components/SectionLandingOffline/SectionLandingOffline";
import SectionContact from "../../components/SectionContact";

/**
 * OfflineHome Page
 * OfflineHome for the dashboard
 * @author Dave Ashenden <dave@thesubversivesagency.com>
 */
const OfflineHome = props => {
  return (
    <React.Fragment>

      <SectionLandingOffline />
      <SectionContact/>
      {/* <SectionFunnel />
      <SectionSendable/>
      <SectionBespoke/>
      <SectionService />
      <SectionPlatform />
      <SectionCRM />
      <SectionPlaybook />
      <SectionContact/> */}
    </React.Fragment>
  );
};

export default OfflineHome;
