import createHistory from "history/createBrowserHistory";
import React from "react";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import Layout from "./components/layout";
import ScrollToTop from "./components/ScrollToTop";
import { AppProvider } from "./context/AppContext";
import ContactPage from "./pages/Contact/Contact";
import OfflineHome from "./pages/OfflineHome/OfflineHome";
import PrivacyPolicyPage from "./pages/PrivacyPolicy/PrivacyPolicy";
import SupplierPage from "./pages/Supplier/Supplier";
// Layout / styling
// import '../node_modules/font-awesome/css/font-awesome.min.css'
import "./sass/index.sass";


// import { Router, Route } from "react-router-dom";
const App = () => {
  
  
  ReactGA.initialize("UA-145181622-1"); 
        const history = createHistory();
        history.listen((location, action) => {
          ReactGA.set({ page: location.pathname });
          ReactGA.pageview(location.pathname);
        });
        return (
          <AppProvider>
            <Helmet>
              <meta charSet="utf-8" />
              <title>matter - We deliver what matters for you</title>
              <meta
                name="description"
                content="matter sources, sends, stores and centralises direct mail, swag, gifts, and anything else your company needs to send."
              />

              <meta
                name="keywords"
                content="matter, Customer retention, Happiness, Sorries, Sorry, Revenue, Apology, Chocolate, Delight, Gifts, Thanks, London"
              />
              <meta name="author" content="matter DM Ltd" />

              <meta property="og:url" content="https://matter.so" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="matter" />
              <meta
                property="og:description"
                content="matter sources, sends, stores and centralises direct mail, swag, gifts, and anything else your company needs to send."
              />

              <meta
                property="og:image"
                content="//matter.so/matter-logo.png"
              />
              <meta
                name="twitter:image"
                content="//matter.so/matter-logo.png"
              />

              <meta name="theme-color" content="#F93663" />
              <link rel="canonical" href="https://matterdm.com/" />
            </Helmet>

            <Router>
              <ScrollToTop>
                <CookieConsent>
                  This website uses cookies to enhance the user
                  experience. Read more in our{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </CookieConsent>
                <Layout>
                  <Switch>
                    <Route
                      path="/privacy-policy"
                      component={PrivacyPolicyPage}
                    />
                    {/* <Route path="/about-us" component={AboutPage} /> */}
                    {/* <Route
                      path="/for/direct-mail"
                      component={CaseStudyDirectMailPage}
                    />
                    <Route
                      path="/for/sentiment-as-a-service"
                      component={CaseStudySorrysAsAServicePage}
                    />
                    <Route
                      path="/for/supercharging-sales"
                      component={CaseStudySuperchargeingSalesPage}
                    /> */}
                    <Route
                      path="/get-in-touch"
                      component={ContactPage}
                    />
                    <Route
                      path="/become-a-supplier"
                      component={SupplierPage}
                    />
                    {/* <Route path="/pricing" component={PricingPage} /> */}
                    {/* <Route path="/pricing" component={PricingPage} /> */}
                    {/* <Route path="/features" component={PricingPage} /> */}
                    {/* <Route path="/" exact={true} component={Home} /> */}
                    <Route path="/" exact={true} component={OfflineHome} />
                  </Switch>
                </Layout>
              </ScrollToTop>
            </Router>
          </AppProvider>
        );
      };;

export default App;
