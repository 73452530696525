import React from 'react'
import PropTypes from 'prop-types'

import NavBar from './NavBar'
// import BetaSplash from './BetaSplash';
import Footer from './Footer';

// import '../sass/index.sass'
// import '../less/ant.less'


const Layout = (props) => (
      <React.Fragment>
        {/* <BetaSplash/> */}
        {/* <NavBar /> */}
        {props.children}
        <Footer/> 
      </React.Fragment>
    
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
