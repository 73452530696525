
import React from 'react'
import styles from './ContactPageStyles.module.sass'
import SectionContact from '../../components/SectionContact';


/**
 * Contact Page
 * 
 * @author Dave Ashenden <dave@thesubversivesagency.com>
 */
const ContactPage = (props) => {
    return (
    <div className="holding">
        <SectionContact/>
    </div>

)
}

export default ContactPage


